<template>
  <div>
    <div class="air__utils__heading">
      <h5>Configuración de Almacén</h5>
    </div>
    <my-breadcrumbs :routes="routes" class="my-4"/>
    <!-- Filtro de plantas para todos los tabs-->
    <div class="row justify-content-center mt-1 mb-4 py-3 bg-white">
      <div class="col">
        <globalPlantFilter @filterValue="filterPlant = $event" @onChangeValue="current = 0"/>
      </div>
    </div>
    <!-- Filtro de plantas para todos los tabs-->
    <template v-if="filterPlant">
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.key" :title="item.title" />
      </a-steps>
      <div class="steps-content">
        <div class="row mb-5 mt-2">
          <div class="col mx-5">
            <template v-if="steps[current].key === 'warehouse'">
              <h4 class="bg-primary rounded text-center text-white py-1 mb-4">Almacén</h4>
              <!-- Slider-->
              <my-spinner
                :load="loadWarehouse"
              />
              <cardSlider :items="warehouses" @selected="goToWarehouse($event)">
                <div slot-scope="{item}">

                  <b-card-img-lazy v-if="item.photo"
                                   :src="item.photo" :alt="item.name"
                                   style="max-height: 100px; min-height: 120px; max-width: 120px;min-width: 100px" img-to/>

                  <b-card-img-lazy v-else
                                   src="/resources/images/content/cards/warehouse.png" :alt="item.name"
                                   style="max-height: 100px; min-height: 120px; max-width: 120px;min-width: 100px" img-to/>
                  <b-card-title>
                    <h6 class="text-secondary mb-1 mt-3 ml-4 mr-0">{{item.name}}</h6>
                  </b-card-title>
                </div>
              </cardSlider>
              <!-- Slider-->
            </template>
            <template v-else>
              <div
                class="row pb-2"
              >
                <div
                  class="col-2 offset-8"
                >
                  <b-button
                    block
                    @click="prev"
                    v-if="current > 0"
                    variant="outline-secondary"
                  >
                    Retroceder <b-icon-chevron-left/>
                  </b-button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 col-lg-8 offset-lg-2 bg-white border-left pb-5">
                  <h4 class="bg-primary rounded text-center text-white py-1 mb-4">Distribución de almacén</h4>
                  <my-spinner
                    :load="loadPosition"
                  />
                  <warehouseGrid :levels="levels" v-show="!loadPosition"/>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <!-- Drawer-->
    <wpositionDrawer @updated="GetWarehouseLevels()"/>
  </div>
</template>

<script>
import cardSlider from '@/components/cardSlider'
import { fractalMixin } from '@/mixins/fractalMixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import wpositionDrawer from '../components/wpositionDrawer'
import warehouseGrid from '../components/location/warehouseGrid'

export default {
  name: 'mainLocations',
  mixins: [fractalMixin],
  components: {
    cardSlider,
    warehouseGrid,
    wpositionDrawer,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
          name: 'operations',
        },
        {
          breadcrumbName: 'Menú de Almacén',
          aIcon: '',
          name: 'warehouse_menu',
        },
        {
          breadcrumbName: 'Configuración de Almacén',
          aIcon: '',
        },
      ],
      filterPlant: '',
      warehouses: [],
      // Steps
      current: 0,
      steps: [
        {
          title: 'Selección de Almacén',
          key: 'warehouse',
        },
        {
          title: 'Resumen y Almacenamiento',
          key: 'positions',
        },
      ],
      levels: [],
      tdetailsTableParams: { include: 'wpositions', 'filter[inputsAndNoPositions]': '' },
      loadWarehouse: false,
      loadPosition: false,
    }
  },
  computed: {
    ...mapState('warehouse', ['selectedPosition', 'titemCart', 'selectedWarehouse']),
    ...mapGetters('warehouse', ['titemCartIds']),
  },
  watch: {
    async filterPlant(newValue) {
      await this.SearchWarehouses()
    },
  },
  methods: {
    ...mapActions('warehouse', ['RESET_WPOSITION', 'SELECT_WAREHOUSE', 'RESET_WAREHOUSE', 'RESET_LOCATIONS', 'DELETE_TITEM', 'TOOGLE_DRAWER', 'RESET_WPFORM']),
    async SearchWarehouses() {
      this.loadWarehouse = true
      const params = {
        'filter[type]': 'Operaciones',
      }
      if (this.filterPlant) params['filter[workCenters]'] = this.filterPlant
      this.warehouses = await this.GetResource('/warehouses', params)
      this.loadWarehouse = false
    },
    async goToWarehouse(warehouse) {
      this.SELECT_WAREHOUSE({
        id: warehouse.id,
        name: warehouse.name,
      })
      this.warehouseSelected = warehouse.id
      this.current = 1
      await this.GetWarehouseLevels()
    },
    async GetWarehouseLevels() {
      this.loadPosition = true
      this.levels = await this.GetResource('/wlevels', {
        include: 'wpositions.titems.tdetails.sapMaterialContracts.materials,wpositions.titems.wpositions',
        'filter[warehouses]': this.selectedWarehouse.id,
      })
      this.loadPosition = false
    },
    Submit() {
      this.AxiosPost('/stockItems', {
        wposition_id: this.selectedPosition,
        titems: this.titemCart,
      }, (resp) => {
        this.RESET_LOCATIONS()
        this.current = 0
      }, (error) => {
        console.log(error)
      })
    },
    // Steps
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    // Resume List
    Remove(item) {
      this.DELETE_TITEM(item)
    },
    // Format table of tdetails
    FormatTable(table) {
      if (table.data.length > 0) {
        table.loading = true
        /*
          Limpiar resultados, es decir mostar lo que:
          -- 1.-NO esten en el estado de cart
          -- 4.- Que sea igual al almacen seleccionado
        */

        table.data = table.data.filter((titem) => {
          // -----------------  1 ------------- 2 ------------------
          return !this.titemCartIds.includes(titem.code) &&
            titem.last_tdetail.transactions.warehouses.id.toString() === this.selectedWarehouse.id
        })
      }
      table.loading = false
    },
  },
  async mounted() {
    this.RESET_WPOSITION()
    this.RESET_WAREHOUSE()
    this.TOOGLE_DRAWER(false)
    this.RESET_WPFORM()
  },
}
</script>

<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
